import React from 'react';
import Grid from '@mui/material/Grid';

import { usePageState, useDataFetchOnLoad } from './Hooks';
import { axiosInstance } from '@/helpers/api_helper';
import { NoQuestionsAvailable } from '@/components/Practice/Cards/Common';
import { AuthContext } from '@/containers/AuthProvider';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { cookieConsentCookieExists } from '@/containers/CookieConsent';
import { useLocation } from "react-router-dom";

import ReactGA from 'react-ga4';

const Wrapper = styled(Grid)`
  display: flex;
  height: 100%;
  align-items: center;
`;

const PracticePage = ({ dataUrl, CardClass, title }) => {
  const { user, setUser, isAuthenticated } = React.useContext(AuthContext);
  const { pathname } = useLocation();
  const [pageState, dispatch] = usePageState();
  const { fetchData, normalizedData, loaded, loading, showSkeleton } = useDataFetchOnLoad(dataUrl);
  let sentence = {};
  let question = {};
  const noQuestions = _isEmpty(normalizedData);

  if (loaded && !noQuestions) {
    question = Object.values(normalizedData.question)[0].attributes;
    sentence = Object.values(normalizedData.sentence)[0].attributes;
  }

  const className = dataUrl.split('/').slice(-1)[0].replace(/(?:^|-)(\w)/g, (match, letter) => letter.toUpperCase());

  function submitPracticeData(evt) {
    const answer = evt.target.value;
    evt.preventDefault();
    dispatch({ type: 'updateAnswer', answer });

    axiosInstance.post(`/api/v1/questions/${question.id}/answer`, { answer, user_id: user.id })
      .then((response) => {
        dispatch({ type: 'correctAnswer' });
        fetchData().then(() => {
          if (isAuthenticated || cookieConsentCookieExists()) {
            ReactGA.event({ category: className, action: 'correct_answer', label: `Question ${question.id}`, value: answer });
          }

          dispatch({ type: 'resetAnswer' });
        });
      }).catch((error) => {
        if (error.response.status !== 401) {
          // I don't think we need to update the state answer anymore given the two input displays
          if (isAuthenticated || cookieConsentCookieExists()) {
            ReactGA.event({ category: className, action: 'incorrect_answer', label: `Question ${question.id}`, value: answer });
          }

          dispatch({ type: 'incorrectAnswer', answer: question.answer });
        }
      }).finally(() => {
        // Set this here to avoid refetching the user meta from the frontend
        if (isAuthenticated) {
          setUser({...user, last_question_type_seen: question.type });
        }
      });
  }

  function fetchNext() {
    fetchData().then(() => {
      dispatch({ type: 'pageClear' });
      if (isAuthenticated || cookieConsentCookieExists()) {
        ReactGA.send({ hitType: 'pageview', page: pathname });
      }
    });
  }

  function handleKeyPress(evt) {
    // If the user types enter to clear the page, trigger a data reload and reset the initial state and fetch new data.
    if (evt.key === 'Enter') {
      if (pageState.practiceDisabled) {
        fetchNext();
        evt.preventDefault();
      } else {
        submitPracticeData(evt);
      }
    }
  }

  React.useEffect(() => {
    // If the user has just typed an error, and we're showing what the correct answer should have been
    // add an event handler to allow the user to type enter to clear the page
    window.addEventListener('keypress', handleKeyPress, false);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [pageState.practiceDisabled, question]);

  const showNoQuestionView = noQuestions && loaded;

  return (
    <Wrapper container justifyContent="center">
      {showNoQuestionView && <NoQuestionsAvailable redirectTo="/dashboard" />}
      {!showNoQuestionView && <CardClass
        showSkeleton={showSkeleton}
        disabled={pageState.practiceDisabled}
        fetchNext={fetchNext}
        handleSubmit={submitPracticeData}
        loading={loading}
        sentence={sentence}
        answer={pageState.answer}
        question={question}
        showSuccess={!!pageState.lastResponseStatus}
        showFailure={pageState.lastResponseStatus === false}
        title={title} /> }
    </Wrapper>
  )
}

export default PracticePage;
