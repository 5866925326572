import React, { useContext, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useMatch } from 'react-router-dom';

import { AuthContext } from '@/containers/AuthProvider';
import { ProfileForm } from '@/components/Session';
import { axiosInstance, convertFromServerTenseArray, convertToServerTenseArray } from '@/helpers/api_helper';

const FORM_DEFAULTS = {
  email: '',
  first_name: '',
  last_name: '',
  include_vosotros: true,
  include_usted: true,
  include_vos: false,
  ignore_accents: false,
};

const Profile = () => {
  const { setUser } = useContext(AuthContext);
  const matches = useMatch('/profile/:start');
  const [profileData, setProfileData] = useState(FORM_DEFAULTS);
  const [initialData, setInitialData] = useState([]);
  const [tenseData, setTenseData] = useState({
    tenses: [],
  });

  function fetchData() {
    axiosInstance.get('/api/v1/users/profile').then((response) => {
      if (response.data.data !== null) {
        const newTenseArray = convertFromServerTenseArray(response.data.data.attributes.tenses);

        setUser(response.data.data.attributes);
        setProfileData({ ...response.data.data.attributes });
        setTenseData({ tenses: newTenseArray });
        setInitialData(newTenseArray);
      }
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const newTenseArray = convertToServerTenseArray(tenseData.tenses);
    const {
      id, role, last_question_type_seen, meta, ...dataToSubmit
    } = { ...profileData };
    const data = { user: { ...dataToSubmit, tenses: newTenseArray } };

    return axiosInstance.patch('/api/v1/users/profile', data).then((response) => {
      setUser(response.data.data.attributes);
    });
  };

  useEffect(() => { fetchData(); }, []);

  return (
    <Grid container justifyContent="center">
      <ProfileForm
        initialData={initialData}
        formData={profileData}
        setFormData={setProfileData}
        tenseData={tenseData}
        setTenseData={setTenseData}
        onSubmit={handleSubmit}
        startTab={matches?.params.start}
      />
    </Grid>
  );
};

export default Profile;
