import React, { useState } from 'react';
import { PageTitle, Section } from '@/components/Static/Common';
import { Helmet } from 'react-helmet-async';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ShowList from './ShowList';
import styled from 'styled-components';

const FormWrapper = styled('div')`
  width: 100%;
  text-align: right;
`;

const VocabularyIndex = () => {
  const [region, setRegion] = useState('All');

  const handleRegionChange = (event: SelectChangeEvent) => {
    setRegion(event.target.value);
  }

  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - TV Vocabulary Lists</title>
        <meta name="description" content="Vocabulary lists for popular Spanish language tv shows on streaming platforms - including Netflix, RTVE and Telemundo." />
        <link rel="canonical" href="https://spanishinaminute.com/vocabulary" />
      </Helmet>

      <PageTitle variant="h4">Vocabulary Lists</PageTitle>
      <Section>
        <Alert severity="info">Watching tv shows and movies in Spanish is a great way to improve your listening and reading comprehension and immerse yourself in Spanish culture.  <br />
        Below is a list of original language Spanish programming you can find on streaming services like <a href="https://www.netflix.com">Netflix</a>, <a href="https://www.rtve.es">RTVE</a> and <a href="https://www.telemundo.com/">Telemundo</a>.</Alert>
        <FormWrapper>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="region-select-label"
              id="region"
              value={region}
              onChange={handleRegionChange}
              label="Region"
            >
              <MenuItem value="All">
                <em>All Regions</em>
              </MenuItem>
              <MenuItem value="Colombia">Colombia</MenuItem>
              <MenuItem value="Mexico">Mexico</MenuItem>
              <MenuItem value="Spain">Spain</MenuItem>
            </Select>
          </FormControl>
        </FormWrapper>
      </Section>
      <ShowList region={region} />
    </>
  );
};

export default VocabularyIndex;
