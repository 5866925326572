import React from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { useQuery } from "@tanstack/react-query";
import { client } from '@/helpers/prismic_client';
import { Helmet } from 'react-helmet-async';

import { PageTitle, Section } from './Common';

const ChangeGroup = styled.ul`
  margin-top: 8px;
`;

const Changelog = () => {
  const { data } = useQuery({
    queryKey: ["changelog"],
    queryFn: () => client.getByType('changelog', {
      orderings: [
        { field: "my.changelog.date", direction: "desc" }
      ],
    })
  });

  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - Changelog</title>
        <meta name="description" content="Recent changes to the website" />
        <link rel="canonical" href="https://spanishinaminute.com/changelog" />
      </Helmet>

      <PageTitle variant="h4">Recent Updates</PageTitle>
      {data && data.results.map((document) => {
        return (
          <Section key={document.data.date}>
            <Typography variant="h5">{document.data.date}</Typography>
            <ChangeGroup>
              {document.data.change.map((change) => {
                return change.details.map((detail) => {
                  return (
                    <li key={detail.text}>
                      {detail.text}
                    </li>
                  );
                });
              })}
            </ChangeGroup>
          </Section>
        );
      })}
    </>
  );
};

export default Changelog;
