import React from 'react';

import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';

type ConjugationDetails = {
  yo: JSX.Element
  tu: JSX.Element
  el: JSX.Element
  nosotros: JSX.Element
  vosotros: JSX.Element
  ellos: JSX.Element
}

export type VerbTable = {
  present?: ConjugationDetails
  preterite?: ConjugationDetails
  subjunctive?: ConjugationDetails
}

export const StyledTable = styled(Table)`
  b {
    color: #ff0000ad;
  }
`;

const ConjugationTable = ({ verb }: { verb: VerbTable }) => {
  return (
    <TableContainer component={Paper}>
      <StyledTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {verb.present && (<TableCell align="left">Present</TableCell>)}
            {verb.preterite && (<TableCell align="left">Preterite</TableCell>)}
            {verb.subjunctive && (<TableCell align="left">Subjunctive</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="right">yo</TableCell>
            {verb.present && (<TableCell align="left">{verb.present.yo}</TableCell>)}
            {verb.preterite && (<TableCell align="left">{verb.preterite.yo}</TableCell>)}
            {verb.subjunctive && (<TableCell align="left">{verb.subjunctive.yo}</TableCell>)}
          </TableRow>
          <TableRow>
            <TableCell align="right">tú</TableCell>
            {verb.present && (<TableCell align="left">{verb.present.tu}</TableCell>)}
            {verb.preterite && (<TableCell align="left">{verb.preterite.tu}</TableCell>)}
            {verb.subjunctive && (<TableCell align="left">{verb.subjunctive.tu}</TableCell>)}
          </TableRow>
          <TableRow>
            <TableCell align="right">él/usted</TableCell>
            {verb.present && (<TableCell align="left">{verb.present.el}</TableCell>)}
            {verb.preterite && (<TableCell align="left">{verb.preterite.el}</TableCell>)}
            {verb.subjunctive && (<TableCell align="left">{verb.subjunctive.el}</TableCell>)}
          </TableRow>
          <TableRow>
            <TableCell align="right">nosotros</TableCell>
            {verb.present && (<TableCell align="left">{verb.present.nosotros}</TableCell>)}
            {verb.preterite && (<TableCell align="left">{verb.preterite?.nosotros}</TableCell>)}
            {verb.subjunctive && (<TableCell align="left">{verb.subjunctive.nosotros}</TableCell>)}
          </TableRow>
          <TableRow>
            <TableCell align="right">vosotros</TableCell>
            {verb.present && (<TableCell align="left">{verb.present.vosotros}</TableCell>)}
            {verb.preterite && (<TableCell align="left">{verb.preterite?.vosotros}</TableCell>)}
            {verb.subjunctive && (<TableCell align="left">{verb.subjunctive.vosotros}</TableCell>)}
          </TableRow>
          <TableRow>
            <TableCell align="right">ellos/uds.</TableCell>
            {verb.present && (<TableCell align="left">{verb.present.ellos}</TableCell>)}
            {verb.preterite && (<TableCell align="left">{verb.preterite.ellos}</TableCell>)}
            {verb.subjunctive && (<TableCell align="left">{verb.subjunctive.ellos}</TableCell>)}
          </TableRow>
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default ConjugationTable;
