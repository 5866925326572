import React from 'react';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ErrorMessage } from '@/components/Shared';
import _intersection from 'lodash/intersection';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import TenseCheckboxes from '@/components/Shared/TenseCheckboxes';
import Alert from '@mui/material/Alert';
import ListSubheader from '@mui/material/ListSubheader';

export const AVAILABLE_TENSES = [
  'Conditional',
  'Future',
  'Imperfect',
  'Present',
  'Preterite',
  'Present Subjunctive',
  'Imperfect Subjunctive',
  'Gerund',
  'Imperative',
  'Negative Imperative',
];

const SubmitButton = styled(Button)`
  margin: 24px 0;
  max-Width: 250px;
`;

const ButtonWrapper = styled.div`
  padding-top: 10px;
  text-align: center;
`;

const StyledSelectControl = styled(FormControl)`
  margin-top: 12px;
  max-width: 300px;
`;

const StyledError = styled(ErrorMessage)`
  padding-top: 0;
  padding-bottom: 8px;
`;

const PageHeader = styled(Typography)`
  text-align: center;
`;

const StyledGrid = styled(Grid)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(2)};
    padding: 0 12px;

    ${theme.breakpoints.up('md')} {
      margin-top: ${theme.spacing(8)};
    }
  `}
`;

const SubTitle = styled(Typography)`
  ${({ theme }) => `
    margin-top: 0;
    margin-bottom: ${theme.spacing(4)};
    text-align: center;

    ${theme.breakpoints.up('md')} {
      margin-top: 0;
      margin-bottom: ${theme.spacing(5)};
    }
  `}
`;

const SignUpAlert = styled(Alert)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(1)};
    text-align: center;

    ${theme.breakpoints.up('md')} {
      margin-top: ${theme.spacing(4)};
      margin-bottom: ${theme.spacing(1)};
    }
  `}
`;

const StyledInputLabel = styled(InputLabel)`
  transform: translate(0px,1.5px) scale(0.75);
  -webkit-transform: translate(0px,1.5px) scale(0.75);
  -moz-transform: translate(0px,1.5px) scale(0.75);
  -ms-transform: translate(0px,1.5px) scale(0.75);
`;

export interface IFormData {
  conjugation_type: string;
  verb_type: string;
  top_100: boolean;
  tenses: string[];
  fetched: boolean;
}

export const defaultState: IFormData = {
  conjugation_type: 'all',
  verb_type: 'all',
  top_100: false,
  tenses: [],
  fetched: false,
};

const ConjugationTypeSelect = ({ disabled, value, handleChange }) => {
  return (
    <Select
      id="conjugation_type"
      onChange={handleChange}
      value={value}
      variant="standard"
      disabled={disabled}
    >
      <MenuItem value="all" key="all">All</MenuItem>
      <MenuItem value="regular_conjugations" key="regular_conjugations">Regular</MenuItem>
      <MenuItem value="irregular_conjugations" key="irregular_conjugations">Irregular</MenuItem>
    </Select>
  );
};

const VerbTypeSelect = ({ disabled, value, handleChange }) => {
  return (
    <Select
      id="verb_type"
      onChange={handleChange}
      value={value}
      variant="standard"
      disabled={disabled}
    >
      <MenuItem value="all" key="all">All</MenuItem>
      <MenuItem value="regular" key="regular">Regular Verbs</MenuItem>
      <MenuItem value="irregular" key="irregular">Irregular Verbs</MenuItem>
      <MenuItem value="ar" key="ar">AR Verbs</MenuItem>
      <MenuItem value="er" key="er">ER Verbs</MenuItem>
      <MenuItem value="ir" key="ir">IR Verbs</MenuItem>
      <ListSubheader>Irregular Verb Groups</ListSubheader>
      <MenuItem value="car_gar" key="car_gar">Ending in car/gar</MenuItem>
      <MenuItem value="cir_cer" key="cir_cer">Ending in cir/cer</MenuItem>
      <MenuItem value="guir" key="guir">Ending in guir</MenuItem>
      <MenuItem value="ger_gir" key="ger_gir">Ending in ger/gir</MenuItem>
      <MenuItem value="zar" key="zar">Ending in zar</MenuItem>
      <MenuItem value="tener" key="tener">Like tener</MenuItem>
      <MenuItem value="e_to_i" key="ir">Stem changing e to i</MenuItem>
      <MenuItem value="e_to_ie" key="e_to_ie">Stem changing e to ie</MenuItem>
      <MenuItem value="o_or_u_to_ue" key="o_or_u_to_ue">Stem changing o/u to ue</MenuItem>
    </Select>
  );
};

type VerbCloseFormProps = {
  isSignedIn: boolean
  formData: IFormData
  initialData: string[]
  setFormData: (data: IFormData) => void
  handleSubmit: (evt: any) => void
}

const VerbClozeForm = ({
  isSignedIn, formData, initialData, setFormData, handleSubmit,
}: VerbCloseFormProps) => {
  const isInvalid = _intersection(formData.tenses, AVAILABLE_TENSES).length === 0;

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container justifyContent="center">
        <StyledGrid item xs={12} sm={8} lg={6}>
          {isSignedIn && (
            <>
              <PageHeader variant="h6">Choose which conjugations to include by verb type and tense:</PageHeader>
              <SubTitle variant="body2">
                (You can further customize the conjugations included in the sentences in your
                {' '}
                <Link to="/profile/verb-tenses">profile</Link>
                .)
              </SubTitle>
            </>
          )}
          <TenseCheckboxes disabled={!isSignedIn} initialData={initialData} formData={formData} setFormData={setFormData} />
          <StyledError message={formData.fetched && isInvalid ? 'You must select at least one tense!' : ''} />

          <FormGroup sx={{ marginTop: '5px' }}>
            <FormLabel>Additional Options</FormLabel>

            <StyledSelectControl>
              <StyledInputLabel>
                Verb types to include
              </StyledInputLabel>
              <VerbTypeSelect
                disabled={!isSignedIn}
                value={formData.verb_type}
                handleChange={(e) => setFormData({ ...formData, verb_type: e.target.value })}
              />
            </StyledSelectControl>

            <StyledSelectControl>
              <StyledInputLabel>
                Conjugation types to include
              </StyledInputLabel>
              <ConjugationTypeSelect
                disabled={!isSignedIn}
                value={formData.conjugation_type}
                handleChange={(e) => setFormData({ ...formData, conjugation_type: e.target.value })}
              />
            </StyledSelectControl>

            <FormControlLabel
              sx={{ marginTop: '10px' }}
              control={(
                <Checkbox
                  disabled={!isSignedIn}
                  color="secondary"
                  checked={formData.top_100}
                  onChange={(e) => setFormData({ ...formData, top_100: e.target.checked })}
                  value="Top 100"
                />
              )}
              label="Only 100 Most Frequent Verbs"
            />
          </FormGroup>

          {!isSignedIn && (<SignUpAlert severity="info" data-cy="sign-in-text">You need to be signed in to customize conjugation practice.</SignUpAlert>)}

          <ButtonWrapper>
            <SubmitButton
              type="submit"
              variant="outlined"
              color="primary"
              disabled={isInvalid}
              fullWidth
              data-cy="submit-button"
            >
              Practice!
            </SubmitButton>
          </ButtonWrapper>
        </StyledGrid>
      </Grid>
    </form>
  );
};

export default VerbClozeForm;
