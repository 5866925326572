import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ListItemIcon from '@mui/material/ListItemIcon';

import styled from 'styled-components';

type ItemProps = {
  title: string
  description?: string
  example?: string
}

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledItemIcon = styled(ListItemIcon)`
  margin-top: 6px;
`;

export const Item = ({ title, description, example }: ItemProps) => {
  return (
    <ListItem alignItems="flex-start">
      <StyledItemIcon>
        <TextSnippetIcon fontSize="small" />
      </StyledItemIcon>
      <ContentWrapper>
        <Typography
          variant="h6"
          sx={{ fontSize: '1.15rem' }}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            sx={{ mb: '6px', fontSize: '0.875rem' }} color="text.secondary"
          >
            {description}
          </Typography>
        )}
        {example && (
          <Typography
            variant="body2"
            sx={{ fontSize: '1rem', fontStyle: 'italic' }}
          >
            {example}
          </Typography>
        )}
      </ContentWrapper>
    </ListItem>
  )
}

const ItemList = ({ children, dense = true }: { children: JSX.Element[], dense?: boolean }) => (
  <List dense={dense}>
    {children}
  </List>
)

export default ItemList;
