import { useLocation } from 'react-router-dom';
import { ignoredConsentPage } from './CookieConsent';

// Component that attaches scroll to top handler on router change
// renders nothing, just attaches side effects
export const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  if (hash !== '') {
    // If this is an interpage route, don't scroll to top
    return null;
  }

  try {
    // If this is a TOS page, don't animate the scroll
    if (ignoredConsentPage(pathname)) {
      window.scrollTo(0, 0);
    } else {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  } catch (error) {
    // just a fallback for older browsers
    window.scrollTo(0, 0);
  }

  // renders nothing, since nothing is needed
  return null;
};

export default ScrollToTop;
