import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 9px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  color: grey;
  vertical-align: middle;
`;

// https://github.com/mui-org/material-ui/issues/11467
const HtmlTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: grey;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 3px 9px;
`;

const TOOLTIPS = {
  conditional: 'Yo sería/I would be',
  future: 'Yo seré/I will be',
  imperfect: 'Yo era/I was being',
  present: 'Yo soy/I am',
  preterite: 'Yo fui/I was',
  present_subjunctive: 'Yo sea/I am',
  imperfect_subjunctive: 'Yo fuera/I was',
  imperative: 'Sé/Be',
  negative_imperative: "No seas/Don't Be",
  conditional_perfect: 'Yo habría sido/I would have been',
  future_perfect: 'Yo habré sido/I will have been',
  past_perfect: 'Yo había sido/I had been',
  present_perfect: 'Yo he sido/I have been',
  present_subjective: 'Yo sea/I am',
  past_perfect_subjunctive: 'Yo hubiera sido/I had been',
  present_perfect_subjunctive: 'Yo haya sido/I have been',
  gerund: 'Siendo/Being',
};

const CheckboxGroup = ({
  allDisabled, disabledOptions, formData, formData: { tenses: selectedTenses }, setFormData, label, values,
}) => {
  // We're just naively storing the list of tenses in an array.
  // So when checkbox value changes, if it wasn't previously in the array
  // add it, or else remove it.
  function updateFormValue(value) {
    return (e) => {
      const currentIndex = selectedTenses.indexOf(value);

      if (currentIndex >= 0) {
        selectedTenses.splice(currentIndex, 1);
      } else {
        selectedTenses.push(value);
      }

      setFormData({ ...formData, tenses: selectedTenses });
    };
  }

  const checkBoxes = values.map((value) => {
    const disabled = allDisabled || disabledOptions.indexOf(value) !== -1;
    const tooltipValue = TOOLTIPS[value.toLowerCase().replace(/ /g, '_')];

    return (
      <div key={value}>
        <StyledFormControlLabel
          disabled={disabled}
          control={(
            <StyledCheckbox
              color="secondary"
              checked={selectedTenses.indexOf(value) >= 0}
              onChange={updateFormValue(value)}
              value={value}
            />
          )}
          label={value}
        />
        <HtmlTooltip
          title={(
            <>
              <Typography color="inherit">{tooltipValue}</Typography>
            </>
          )}
          arrow
        >
          <StyledInfoIcon fontSize="small" />
        </HtmlTooltip>
      </div>
    );
  });

  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      {checkBoxes}
    </FormGroup>
  );
};

CheckboxGroup.propTypes = {
  formData: PropTypes.object,
  label: PropTypes.string,
  values: PropTypes.array,
  disabledOptions: PropTypes.array,
};

CheckboxGroup.defaultProps = {
  disabledOptions: [],
  formData: {
    tenses: [],
  },
};

export default CheckboxGroup;
