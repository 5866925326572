import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SnackbarContext } from '@/containers/SnackbarProvider';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { TabPanel, TenseCheckboxes } from '@/components/Shared';
import UserDataForm from './UserDataForm';

const SubmitButton = styled(Button)`
  margin: 24px 0;
  max-Width: 250px;
`;

const StyledPanel = styled(TabPanel)`
  > div {
    padding: 32px 0 0;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 10px;
  text-align: center;
`;

const Description = styled(Typography)`
  padding-bottom: 18px;
  font-style: italic;
`;

const StyledGrid = styled(Grid)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(1)};
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${theme.breakpoints.up('md')} {
      margin-top: ${theme.spacing(8)};
    }
  `}
`;

const UserDataTab = styled(StyledPanel)`
  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      min-height: 280px;
    }
  `}
`;

const ProfileForm = ({
  formData, setFormData, initialData, tenseData, setTenseData, onSubmit, startTab,
}) => {
  const [tabState, setTabState] = useState(startTab || 'profile');
  const { setSnackbarOpen, setAlertState } = useContext(SnackbarContext);

  const handleChange = (event, newValue) => {
    setTabState(newValue);
  };

  const handleSubmit = (e) => {
    onSubmit(e).then(() => {
      setAlertState({ message: 'Settings saved.', severity: 'success' });
    }).catch(() => {
      setAlertState({ message: 'Something went wrong!', severity: 'error' });
    }).finally(() => {
      setSnackbarOpen(true);
    });
  };

  return (
    <StyledGrid item xs={12} sm={8} lg={6}>
      <form noValidate onSubmit={handleSubmit}>
        <Tabs
          value={tabState}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          data-cy="tabs"
        >
          <Tab value="profile" icon={<PersonIcon />} iconPosition="end" label="Profile" data-cy="profile-tab" />
          <Tab value="verb-tenses" icon={<SettingsIcon />} iconPosition="end" label="Verb Tenses" data-cy="tenses-tab" />
        </Tabs>

        <UserDataTab data-cy="my-panel" value={tabState} index="profile">
          <Box p={3}>
            <UserDataForm formData={formData} setFormData={setFormData} />
          </Box>
        </UserDataTab>
        <StyledPanel value={tabState} index="verb-tenses">
          <Box p={3}>
            <Description variant="body1" align="justify">
              These settings control the verb tenses you will see across all questions on the site
              and will be used to populate the defaults for verb cloze (conjugation) questions - they represent the
              verb tenses you're comfortable seeing in sentences.
            </Description>
            <TenseCheckboxes formData={tenseData} initialData={initialData} disabledOptions={['Present', 'Gerund']} setFormData={setTenseData} />
          </Box>
        </StyledPanel>

        <ButtonWrapper>
          <SubmitButton
            type="submit"
            variant="outlined"
            color="primary"
            fullWidth
            data-cy="submit-button"
          >
            Submit
          </SubmitButton>
        </ButtonWrapper>
      </form>
    </StyledGrid>
  );
};

ProfileForm.propTypes = {
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
};

ProfileForm.defaultProps = {
  formData: {
    tenses: [],
  },
  startTab: '',
};

export default ProfileForm;
