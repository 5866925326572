import React from 'react';

import { Helmet } from 'react-helmet-async';
import { PageTitle, Section } from '../Common';
import Paper from '@mui/material/Paper';
import ItemList, { Item } from '@/components/Static/Common/ItemList';

import Alert from '@mui/material/Alert';

const VerbsThatTakePrepositions = () => {
  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - Verbs That Take Prepositions</title>
        <link rel="canonical" href="https://spanishinaminute.com/guides/verbs-that-take-prepositions" />
        <meta name="description" content="List of Spanish verbs that need prepositions" />
        <meta name="keywords" content="Spanish, Grammar, Verbs, Prepositions" />
      </Helmet>
      <PageTitle variant="h4" component="h1">Verbs That Take Prepositions</PageTitle>
      <Alert severity="info" sx={{ marginBottom: '12px' }}>
        Certain verbs in Spanish take prepositions to have a set meeting, much like in English.  Below is a list of verbs that are accompanied
        by the prepositions <b>a</b>, <b>de</b>, <b>con</b> and <b>en</b> and are then followed by a noun or an infinitive.
      </Alert>
      <Section>
        <Paper>
          <ItemList dense={false}>
            <Item title="Abstenerse de" description="To abstain from doing something" example="Me abstengo de votar." />
            <Item title="Aburrirse con" description="To be bored with" example="Ellos se aburrieron con el nuevo juguete." />
            <Item title="Abusar de" description="To take advantage of something" example="El rey abusó de su poder." />
            <Item title="Acabar con" description="To finish off" example="Quiero ser la persona que acabe con esto." />
            <Item title="Acabar de" description="To have just finished" example="Acabo de comer pizza." />
            <Item title="Acordarse de" description="To remember something" example="¿Se acuerdan ustedes de nosotros?" />
            <Item title="Acostumbrarse a" description="To be used to something" example="Yo no estoy acostumbrado a trabajar duro." />
            <Item title="Alcanzar a" description="To manage to" example="No alcanzo a ver bien." />
            <Item title="Aprender a" description="To learn to" example="Aprendí a vivir sin ella." />
            <Item title="Arrepentirse de" description="To regret (doing) something" example="No me arrepiento de nada." />
            <Item title="Asistir a" description="To attend" example="Asistí a su boda." />
            <Item title="Aspirar a" description="To aspire to" example="En cinco años aspiro a graduarme de la universidad." />
            <Item title="Atreverse a" description="To dare to" example="Casi ni me atrevo a pedírselo." />
            <Item title="Ayudar a" description="To help to" example="Los ayudé a entrenar." />
            <Item title="Burlarse de" description="To make fun of" example="Se burlaron de mi idea." />
            <Item title="Cambiar de" description="To change" example="¿Cambiarías de asiento conmigo?" />
            <Item title="Cansarse de" description="To grow tired of" example="Te cansaste de esperarla." />
            <Item title="Casarse con" description="To marry" example="Me voy a casar con ella en junio." />
            <Item title="Comenzar a" description="To begin to" example="Comienza a caminar." />
            <Item title="Condenar a" description="To condemn to" example="Espero que no me condenes a vivir solo." />
            <Item title="Confiar en" description="To trust in" example="Nunca confiaré en ella de nuevo."  />
            <Item title="Confundir con" description="To confuse with" example="Te confundí con tu hermano mayor." />
            <Item title="Consistir en" description="To consist of" example="La tarea consiste en leer un libro." />
            <Item title="Contar con" description="To count on" example="Contamos con tu ayuda." />
            <Item title="Convertirse en" description="To become" example="Se convirtieron en marido y mujer." />
            <Item title="Decidirse a" description="To decide to" example="Estoy decidido a aprender." />
            <Item title="Dedicarse a" description="To devote to" example="Ella se dedicaba a sus hijos." />
            <Item title="Dejar de" description="To stop doing something" example="Juan decidió dejar de mentir." />
            <Item title="Depender de" description="To depend on" example="Eso no depende de mí." />
            <Item title="Despedirse de" description="To say goodbye to" example="Fuimos a la estación a despedirnos de ella." />
            <Item title="Disculparse de" description="To apologize for" />
            <Item title="Disfrutar de" description="To enjoy something" example="Disfruta de tu juventud mientras dure." />
            <Item title="Divorciarse de" description="To divorce" example="Tom se divorció de Mary." />
            <Item title="Empezar a" description="To begin to" example="Empezaste a llorar." />
            <Item title="Enseñar a" description="To teach to" example="Ella me enseñó a nadar." />
            <Item title="Insistir en" description="To insist on" example="Él insistió en su opinión." />
            <Item title="Invitar a" description="To invite to" example="Mi jefe me invitó a cenar." />
            <Item title="Llegar a" description="To succeed in" example="¿Llegaste a leer el libro completo?" />
            <Item title="Mandar a" description="To send to" example="Mi papá me mandó a lavar el carro." />
            <Item title="Meterse en" description="To be involved in" />
            <Item title="Montar en" description="To ride" example="Algunos pasajeros montaron en el avión." />
            <Item title="Morir de" description="To die from" example="Me estoy muriendo de hambre." />
            <Item title="Negarse a" description="To refuse to" example="Ella se negó a hablar inglés." />
            <Item title="Obligar a" description="To force to" example="Ella le obligó a hacerlo." />
            <Item title="Ocuparse de" description="To be busy with/take care of" example="¿Cómo nos ocupamos de esto?" />
            <Item title="Oler a" description="To smell like" example="Todo olía a tocino." />
            <Item title="Olvidarse de" description="To forget to" example="No te olvides de poner un sello." />
            <Item title="Parar de" description="To stop doing something" example="No para de quejarse." />
            <Item title="Pensar de" description="To have an opinion about" example="¿Qué piensas de este libro?" />
            <Item title="Pensar en" description="To think about" example="Estoy pensando en ti." />
            <Item title="Ponerse a" description="To begin to" example="Todos se pusieron a pelear." />
            <Item title="Regresar a" description="To go back to" />
            <Item title="Saber a" description="To taste like" example="La sopa sabe a grasa." />
            <Item title="Salir a" description="To go out to" example="Ellos salen a trabajar temprano." />
            <Item title="Servir de" description="To act as" example="No servirá de nada seguir pensando." />
            <Item title="Sonar a" description="To sound like" example="Eso me suena a mi hermana." />
            <Item title="Soñar con" description="To dream about" example="A veces sueño con mi madre." />
            <Item title="Tarder en" description="To delay in" example="¿Cuánto tardaremos en volver?" />
            <Item title="Terminar de" description="To finish" example="Terminé de escribir." />
            <Item title="Tratar de" description="To try to" example="Ella trata de hacer todo sola." />
            <Item title="Venir a" description="To come to" example="Nadie vino a la fiesta." />
            <Item title="Volver a" description="To go back to" example="Volví a la ciudad." />
          </ItemList>
        </Paper>
      </Section>
    </>
  );
};

export default VerbsThatTakePrepositions;
