import React from 'react';
import PropTypes from 'prop-types';

import CheckboxGroup from '@/components/Shared/CheckboxGroup';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

const TENSES = [
  'Conditional',
  'Future',
  'Imperfect',
  'Present',
  'Preterite',
  'Gerund',
];

const COMMANDS = [
  'Imperative',
  'Negative Imperative',
];

// const PERFECT_TENSES = [
//   'Conditional Perfect',
//   'Future Perfect',
//   'Past Perfect',
//   'Present Perfect'
// ];

const SUBJUNCTIVE_TENSES = [
  'Present Subjunctive',
  'Imperfect Subjunctive',
  // 'Past Perfect Subjunctive',
  // 'Present Perfect Subjunctive'
];

const TenseCheckboxes = ({ disabled, disabledOptions, initialData, formData, setFormData }) => {
  const { tenses: selectedTenses } = formData;
  const allChecked = selectedTenses?.length === 10;

  const toggleAllChecked = (event) => {
    if (event.target.checked) {
      setFormData({
        ...formData,
        tenses: [
          ...TENSES,
          ...COMMANDS,
          ...SUBJUNCTIVE_TENSES
        ]
      });
    } else {
      setFormData({ ...formData, tenses: initialData });
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <CheckboxGroup
          allDisabled={disabled}
          label="Simple Tenses"
          values={TENSES}
          formData={formData}
          setFormData={setFormData}
          disabledOptions={disabledOptions}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CheckboxGroup
          allDisabled={disabled}
          label="Commands"
          values={COMMANDS}
          formData={formData}
          setFormData={setFormData}
          disabledOptions={disabledOptions}
        />

        <Box sx={{ paddingTop: '18px' }}>
          <CheckboxGroup
            allDisabled={disabled}
            label="Subjunctive Tenses"
            values={SUBJUNCTIVE_TENSES}
            formData={formData}
            setFormData={setFormData}
            disabledOptions={disabledOptions}
          />
        </Box>

      </Grid>
      <Box sx={{ paddintTop: '8px' }}>
        <FormControlLabel
          control={(
            <Checkbox
              disabled={disabled}
              color="secondary"
              checked={allChecked}
              onChange={toggleAllChecked}
            />
          )}
          label="All Tenses"
        />
      </Box>
    </Grid>
  );
};

TenseCheckboxes.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default TenseCheckboxes;
