import React from 'react';

import { Helmet } from 'react-helmet-async';
import { PageTitle, Section } from '../Common';

import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { ConjugationTable } from '../Common';
import Stack from '@mui/material/Stack';
import { HashLink as Link } from 'react-router-hash-link';

import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  margin-top: 8px;
  margin-bottom: 12px;
`;

const StyledLink = styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
  `}
`;

const VerbSection = styled('section')`
  margin-top: 16px;
`;

const Examples = styled('div')`
  margin-top: 6px;
`;

const IrregularVerbGroups = () => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - Irregular Verb Groups</title>
        <link rel="canonical" href="https://spanishinaminute.com/guides/irregular-verb-groups" />
        <meta name="description" content="List of Spanish irregular Spanish verb groups" />
        <meta name="keywords" content="Spanish, Grammar, Verbs, Prepositions" />
      </Helmet>
      <PageTitle variant="h4" component="h1">Irregular Verb Groups</PageTitle>
      <Section>

        <ul>
          <li><StyledLink smooth to={{pathname: '/guides/irregular-verb-groups', hash: '#ends-with-car-gar'}}>Ending with car/gar</StyledLink></li>
          <li><StyledLink smooth to={{pathname: '/guides/irregular-verb-groups', hash: '#ends-with-cer-cir'}}>Ending with cer/cir</StyledLink></li>
          <li><StyledLink smooth to={{pathname: '/guides/irregular-verb-groups', hash: '#ends-with-ger-gir'}}>Ending with ger/gir</StyledLink></li>
          <li><StyledLink smooth to={{pathname: '/guides/irregular-verb-groups', hash: '#ends-with-guir'}}>Ending with guir</StyledLink></li>
          <li><StyledLink smooth to={{pathname: '/guides/irregular-verb-groups', hash: '#ends-with-zar'}}>Ending with zar</StyledLink></li>
          <li><StyledLink smooth to={{pathname: '/guides/irregular-verb-groups', hash: "#stem-changing-e-to-i"}}>Stem changing e to i</StyledLink></li>
          <li><StyledLink smooth to={{pathname: '/guides/irregular-verb-groups', hash: '#stem-changing-e-to-ie'}}>Stem changing e to ie</StyledLink></li>
          <li><StyledLink smooth to={{pathname: '/guides/irregular-verb-groups', hash: "#stem-changing-o-u-to-ue"}}>Stem changing o/u to ue</StyledLink></li>
        </ul>

        <Stack spacing={2}>
          <VerbSection>
            <Typography id="ends-with-car-gar" variant="h5" component="h2">Ending with car/gar</Typography>
            <StyledAlert severity="info">These verbs change the letter <b>c</b> to <b>qu</b> in the first person preterite and subjunctive.
            <Examples>Examples include: buscar, dedicar, explicar, llegar, pagar, sacar, secar and tocar.</Examples></StyledAlert>
            <ConjugationTable verb={{
              preterite: {
                yo: <span>bus<b>qu</b>é</span>,
                tu: <span>buscaste</span>,
                el: <span>buscó</span>,
                nosotros: <span>buscamos</span>,
                vosotros: <span>buscasteis</span>,
                ellos: <span>buscaron</span>
              },
              subjunctive: {
                yo: <span>bus<b>qu</b>e</span>,
                tu: <span>bus<b>qu</b>es</span>,
                el: <span>bus<b>qu</b>e</span>,
                nosotros: <span>bus<b>qu</b>emos</span>,
                vosotros: <span>bus<b>qu</b>éis</span>,
                ellos: <span>bus<b>qu</b>en</span>
              }
            }} />
          </VerbSection>

          <VerbSection>
            <Typography id="ends-with-cer-cir" variant="h5" component="h2">Ending with cer/cir</Typography>
            <StyledAlert severity="info">These verbs add the letter <b>z</b> in the first person present and subjunctive.
            <Examples>Examples include: agradecer, conocer, introducir, ofrecer, pertenecer, producir and traducir.</Examples></StyledAlert>
            <ConjugationTable verb={{
              present: {
                yo: <span>cono<b>z</b>co</span>,
                tu: <span>conoces</span>,
                el: <span>conoce</span>,
                nosotros: <span>conocemos</span>,
                vosotros: <span>conocéis</span>,
                ellos: <span>conocen</span>
              },
              subjunctive: {
                yo: <span>cono<b>z</b>ca</span>,
                tu: <span>cono<b>z</b>cas</span>,
                el: <span>cono<b>z</b>ca</span>,
                nosotros: <span>cono<b>z</b>camos</span>,
                vosotros: <span>cono<b>z</b>cáis</span>,
                ellos: <span>cono<b>z</b>can</span>
              }
            }} />
          </VerbSection>

          <VerbSection>
            <Typography id="ends-with-ger-gir" variant="h5" component="h2">Ending with ger/gir</Typography>
            <StyledAlert severity="info">These verbs change <b>g</b> to <b>j</b> in the first person present and subjunctive.
            <Examples>Examples include: coger, corregir, dirigir, escoger, exigir, fingir and proteger.</Examples></StyledAlert>
            <ConjugationTable verb={{
              present: {
                yo: <span>prote<b>j</b>o</span>,
                tu: <span>protges</span>,
                el: <span>protege</span>,
                nosotros: <span>protegemos</span>,
                vosotros: <span>protegéis</span>,
                ellos: <span>protegen</span>
              },
              subjunctive: {
                yo: <span>prote<b>j</b>a</span>,
                tu: <span>prote<b>j</b>as</span>,
                el: <span>prote<b>j</b>a</span>,
                nosotros: <span>prote<b>j</b>amos</span>,
                vosotros: <span>prote<b>j</b>áis</span>,
                ellos: <span>prote<b>j</b>an</span>
              }
            }} />
          </VerbSection>

          <VerbSection>
            <Typography id="ends-with-guir" variant="h5" component="h2">Ending with guir</Typography>
            <StyledAlert severity="info">These are stem changing e to i verbs that also have the stem changes seen in ger/gir verbs.
            <Examples>Examples include: conseguir, distinguir, extinguir, perseguir and seguir.</Examples></StyledAlert>
            <ConjugationTable verb={{
              present: {
                yo: <span>cons<b>ig</b>o</span>,
                tu: <span>cons<b>i</b>gues</span>,
                el: <span>cons<b>i</b>gue</span>,
                nosotros: <span>conseguimos</span>,
                vosotros: <span>conseguís</span>,
                ellos: <span>cons<b>i</b>guen</span>
              },
              preterite: {
                yo: <span>conseguí</span>,
                tu: <span>conseguiste</span>,
                el: <span>cons<b>i</b>guió</span>,
                nosotros: <span>conseguimos</span>,
                vosotros: <span>conseguisteis</span>,
                ellos: <span>cons<b>i</b>guieron</span>
              },
              subjunctive: {
                yo: <span>cons<b>ig</b>a</span>,
                tu: <span>cons<b>ig</b>as</span>,
                el: <span>cons<b>ig</b>a</span>,
                nosotros: <span>cons<b>ig</b>amos</span>,
                vosotros: <span>cons<b>ig</b>áis</span>,
                ellos: <span>cons<b>ig</b>an</span>
              }
            }} />
          </VerbSection>

          <VerbSection>
            <Typography id="ends-with-zar" variant="h5" component="h2">Ending with zar</Typography>
            <StyledAlert severity="info">These verbs change the letter <b>z</b> to <b>c</b> in the first person preterite and subjunctive.
            <Examples>Examples include: almorzar, comenzar, cruzar, empezar and rezar.</Examples></StyledAlert>
            <ConjugationTable verb={{
              preterite: {
                yo: <span>re<b>c</b>é</span>,
                tu: <span>rezaste</span>,
                el: <span>rezó</span>,
                nosotros: <span>rezamos</span>,
                vosotros: <span>rezasteis</span>,
                ellos: <span>rezaron</span>
              },
              subjunctive: {
                yo: <span>re<b>c</b>e</span>,
                tu: <span>re<b>c</b>es</span>,
                el: <span>re<b>c</b>e</span>,
                nosotros: <span>re<b>c</b>emos</span>,
                vosotros: <span>re<b>c</b>éis</span>,
                ellos: <span>re<b>c</b>en</span>
              }
            }} />
          </VerbSection>

          <VerbSection>
            <Typography id="stem-changing-e-to-i" variant="h5" component="h2">Stem changing e to i</Typography>
            <StyledAlert severity="info">These verbs change their stem from <b>e</b> to <b>i</b> in the present, preterite and subjunctive.
            <Examples>Examples include: competir, conseguir, corregir, decir, despedir, elegir, impedir, medir, pedir, perseguir, reír, repetir, servir and vestir.</Examples>
            </StyledAlert>
            <ConjugationTable verb={{
              present: {
                yo: <span>m<b>i</b>do</span>,
                tu: <span>m<b>i</b>des</span>,
                el: <span>m<b>i</b>de</span>,
                nosotros: <span>medimos</span>,
                vosotros: <span>medís</span>,
                ellos: <span>m<b>i</b>den</span>
              },
              preterite: {
                yo: <span>medí</span>,
                tu: <span>mediste</span>,
                el: <span>m<b>i</b>dió</span>,
                nosotros: <span>medimos</span>,
                vosotros: <span>medisteis</span>,
                ellos: <span>m<b>i</b>dieron</span>
              },
              subjunctive: {
                yo: <span>m<b>i</b>da</span>,
                tu: <span>m<b>i</b>das</span>,
                el: <span>m<b>i</b>da</span>,
                nosotros: <span>m<b>i</b>damos</span>,
                vosotros: <span>m<b>i</b>dáis</span>,
                ellos: <span>m<b>i</b>dan</span>
              }
            }} />
          </VerbSection>

          <VerbSection>
            <Typography id="stem-changing-e-to-ie" variant="h5" component="h2">Stem changing e to ie</Typography>
            <StyledAlert severity="info">These verbs change their stem from <b>e</b> to <b>ie</b> in the present and the subjunctive.
            <Examples>Examples include: cerrar, comenzar, confesar, convertir, empezar, entender, hervir, mentir, negar, pensar, perder, preferir and querer.</Examples>
            </StyledAlert>
            <ConjugationTable verb={{
              present: {
                yo: <span>c<b>i</b>erro</span>,
                tu: <span>c<b>i</b>erras</span>,
                el: <span>c<b>i</b>erra</span>,
                nosotros: <span>cerramos</span>,
                vosotros: <span>cerráis</span>,
                ellos: <span>c<b>i</b>erran</span>
              },
              subjunctive: {
                yo: <span>c<b>i</b>erre</span>,
                tu: <span>c<b>i</b>erres</span>,
                el: <span>c<b>i</b>erre</span>,
                nosotros: <span>cerremos</span>,
                vosotros: <span>cerréis</span>,
                ellos: <span>c<b>i</b>erren</span>
              }
            }} />
          </VerbSection>

          <VerbSection>
            <Typography id="stem-changing-o-u-to-ue" variant="h5" component="h2">Stem changing o/u to ue</Typography>
            <StyledAlert severity="info">These verbs change their stem from <b>o</b> or <b>u</b> to <b>ue</b> in the present and subjunctive.
            <Examples>Examples include: contar, dormir, encontrar, jugar, llover, morir, mostrar, mover, probar, recordar, resolver, rogar, soñar and volver.</Examples></StyledAlert>
            <ConjugationTable verb={{
              present: {
                yo: <span>enc<b>ue</b>ntro</span>,
                tu: <span>enc<b>ue</b>ntras</span>,
                el: <span>enc<b>ue</b>ntra</span>,
                nosotros: <span>encontramos</span>,
                vosotros: <span>encontráis</span>,
                ellos: <span>enc<b>ue</b>ntran</span>
              },
              subjunctive: {
                yo: <span>enc<b>ue</b>ntre</span>,
                tu: <span>enc<b>ue</b>ntres</span>,
                el: <span>enc<b>ue</b>ntre</span>,
                nosotros: <span>encontremos</span>,
                vosotros: <span>encontréis</span>,
                ellos: <span>enc<b>ue</b>ntren</span>
              }
            }} />
          </VerbSection>
        </Stack>
      </Section>
    </>
  );
};

export default IrregularVerbGroups;
