import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { Section } from '@/components/Static/Common';
import { ImageWithSkeleton } from '@/components/Shared';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { useQuery } from "@tanstack/react-query";
import { client } from '@/helpers/prismic_client';
import * as prismic from "@prismicio/client";

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const TitleBlock = styled('div')`
  display: flex;
`;

const FlagImg = styled('img')`
  width: 20px;
  margin-left: 8px;
`;

const ShowName = ({ countryName, displayName }: { countryName: string, displayName: string }) => {
  let Flag;

  switch(countryName) {
    case 'Colombia':
      Flag = <FlagImg src="https://hatscripts.github.io/circle-flags/flags/co.svg" />;
      break;
    case 'Mexico':
      Flag = <FlagImg src="https://hatscripts.github.io/circle-flags/flags/mx.svg" />;
      break;
    case 'Spain':
      Flag = <FlagImg src="https://hatscripts.github.io/circle-flags/flags/es.svg" />;
      break;
    default:
      Flag = null;
  }

  return (
    <TitleBlock>
      {displayName}{Flag}
    </TitleBlock>
  )
}

const ShowList = ({ region }: { region: string }) => {
  const queryOptions = {
    orderings: [
      { field: "my.show.display_name", direction: "asc" }
    ]
  };

  if (region && region !== 'All') {
    queryOptions.filters = [
      prismic.filter.fulltext("my.show.region", region)
    ]
  }

  const { data } = useQuery({ 
    queryKey: ["shows", region], 
    queryFn: () => client.getByType('show', {
      graphQuery: `{
        show {
          display_name
          region
          poster
        }
      }`,
      ...queryOptions
    })
  }); 

  return (
    <Section>
      <Grid container rowSpacing={{ xs: 2, lg: 3 }} columnSpacing={2}>
        {data && data.results.map((document) => {
          return (
            <Grid item xs={6} sm={4} lg={3} xl={2} key={document.uid}>
              <StyledLink component={RouterLink} underline="hover" variant="h6" to={document.uid}>
                <ImageWithSkeleton height="275" width="200" alt={document.data.poster.alt} src={document.data.poster.url} />
                <ShowName displayName={document.data.display_name} countryName={document.data.region} />
              </StyledLink>
            </Grid>
          );
        })}
      </Grid>
    </Section>
  );
};

export default ShowList;
