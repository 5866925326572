import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { AuthContext } from '@/containers/AuthProvider';
import IconButton from "@mui/material/IconButton";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { Link, useNavigate, useLocation } from 'react-router-dom';

const StyledLink = styled(Link)`
  ${({ theme }) => `
    display: flex;
    color: ${theme.palette.text.primary};
    text-decoration: none;
  `}
`;

const Container = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledListItem = styled(ListItem)`
  padding: 0;
`;

const StaticLinkContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 8px;

  ${StyledLink} {
    margin: 0 10px;
  }
`;

const SideBarItem = ({ routeName, displayName, secondaryAction, ...rest }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const route = `/${routeName}`;
  let selected = route === pathname;

  if (route === '/dashboard' && pathname === '/') {
    selected = true;
  }

  const navigateTo = (path) => () => navigate(path);

  return (
    <StyledListItem secondaryAction={secondaryAction}>
      <ListItemButton selected={selected} key={routeName} onClick={navigateTo(route)} {...rest}>
        <ListItemText>
          {displayName}
        </ListItemText>
      </ListItemButton>
    </StyledListItem>
  );
};

const DashboardLink = styled(SideBarItem)`
  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    padding-left: ${theme.spacing(2)};
  `}
`;

const SideBar = ({ isLanding, open, closeDrawer, openDrawer }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { isAdmin } = React.useContext(AuthContext);

  const toggleDrawer = event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    closeDrawer();
  };

  const permanentDrawer = useMediaQuery(theme.breakpoints.up('md'));

  if (isLanding) return null;

  return (
    <SwipeableDrawer
      open={open || permanentDrawer}
      onClose={closeDrawer}
      onOpen={openDrawer}
      variant={permanentDrawer ? 'permanent' : 'temporary'}
    >
      <Container
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
        data-cy="sidebar-wrapper"
      >
        <div>
          <DashboardLink routeName="dashboard" displayName="Dashboard" />
          <List
            subheader={(
              <ListSubheader component="div" id="nested-list-subheader">
                Grammar Practice
              </ListSubheader>
            )}
            data-cy="grammar-practice"
          >
            <SideBarItem routeName="para-por" displayName="Para/Por" />
            <SideBarItem routeName="subjunctive-indicative" displayName="Subjunctive/Indicative" />
            <SideBarItem
              routeName="verb-preposition"
              displayName="Verbs with Prepositions"
              secondaryAction={(
                <IconButton edge="end" onClick={() => navigate('/guides/verbs-that-take-prepositions')}>
                  <TextSnippetIcon />
                </IconButton>
              )} />
            <SideBarItem routeName="direct-indirect-object" displayName="Direct/Indirect Object" />
            {isAdmin && <SideBarItem routeName="preterite-imperfect" displayName="Preterite/Imperfect" />}
            {isAdmin && <SideBarItem routeName="ser-estar" displayName="Ser/Estar" />}
          </List>
          <List
            subheader={(
              <ListSubheader component="div" id="nested-list-subheader">
                Conjugation Practice
              </ListSubheader>
            )}
            data-cy="conjugation-practice"
          >
            <SideBarItem
              routeName="verb-cloze"
              displayName="Conjugations"
              secondaryAction={(
                <IconButton edge="end" onClick={() => navigate('/guides/irregular-verb-groups')}>
                  <TextSnippetIcon />
                </IconButton>
              )} />
          </List>
          <List
            subheader={(
              <ListSubheader component="div" id="nested-list-subheader">
                Additional Resources
              </ListSubheader>
            )}
            data-cy="vocabulary"
          >
            <SideBarItem routeName="vocabulary" displayName="TV Vocabulary Lists" />
          </List>
        </div>
        <StaticLinkContainer>
          <StyledLink to="/changelog">
            <ListItemText>
              Changes
            </ListItemText>
          </StyledLink>
          /
          <StyledLink to="/terms">
            <ListItemText>
              Terms
            </ListItemText>
          </StyledLink>
          /
          <StyledLink to="/privacy">
            <ListItemText>
              Privacy
            </ListItemText>
          </StyledLink>
        </StaticLinkContainer>
      </Container>
    </SwipeableDrawer>
  );
}

SideBar.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

SideBar.defaultProps = {
  open: false,
};

export default SideBar;
